<template>
    <BaseInformantDetailsTest
        :key="versionkeyreload"
        :InformantDetails="informant" 
        :entrycode="entrycode" 
        :passed="true"  />
</template>

<script>
import { get } from 'vuex-pathify'

import UnsavedGuardMixin from '@/mixins/UnsavedGuard.js'
import PassEntrycode from '@/mixins/PassEntrycode.js'

export default {
    mixins: [UnsavedGuardMixin, PassEntrycode],
    computed: {
        ...get('version', {
            versionkeyreload: 'reload'
        }),
        // FIXME: could be new object in case we are just adding a new incident
        informant() {
            // let _informant = this.$store.state.incidents.incident.data.InformantDetails
            let _informant = this.$store.state.incidentsnew.incident.data.InformantDetails
            return _informant
        },
    },
    methods: {
    },
}
</script>
